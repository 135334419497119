import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/admin_datetimepicker.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown';
import 'angular-bootstrap-datetimepicker';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('adminDatetimepicker', [
    '$injector',

    function factory($injector) {
        const dateHelper = $injector.get('dateHelper');

        return {
            restrict: 'E',
            templateUrl,
            require: 'ngModel',
            scope: {
                minView: '<?',
                hideRefTimezone: '<?',
                disabled: '@', // use ng-disabled
            },
            link(scope, _elem, _attrs, ngModelCtrl) {
                scope.dateHelper = dateHelper;
                scope.minView = scope.minView || 'hour';

                // Use ng-disabled
                scope.$watch('disabled', val => {
                    scope.isDisabled = !!val;
                });

                Object.defineProperty(scope, 'dateTime', {
                    get() {
                        return ngModelCtrl.$modelValue;
                    },
                    set(val) {
                        if (val !== ngModelCtrl.$modelValue) {
                            scope.$applyAsync(() => {
                                ngModelCtrl.$setViewValue(val);
                                ngModelCtrl.$render();
                            });
                        }
                    },
                });
            },
        };
    },
]);
