import angularModule from 'Admin/angularModule/scripts/admin_module';
import template from 'Admin/angularModule/views/edit_period_exercise.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editPeriodExercise', [
    '$injector',

    function factory($injector) {
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                exercise: '<',
                period: '=',
            },
            link(scope) {
                scope.duplicateExercise = exercise => {
                    if (exercise && typeof exercise === 'object') {
                        const { _id, ...attrs } = exercise;

                        // In March 2025, we dropped support for uploading new documents to period exercises.
                        // At the time, we had old exercises with documents still in them that could theoretically
                        // be duplicated into new exercises. Here, we delete the document property of attrs to
                        // prevent that scenario from creating a new exercise with a document.
                        delete attrs.document;
                        // At the same time, we dropped support for slack_room_overrides, something that hasn't been
                        // used since dropping support for Cohorts with multiple Slack rooms. The last Cohort cycle
                        // to use slack_room_overrides was the 49 cycle which graduated on 2024-02-20. This is here
                        // simply to prevent the duplication of any 49 or prior exercises with slack_room_overrides.
                        delete attrs.slack_room_overrides;
                        scope.period.addExercise(attrs);
                    }
                };

                // FIXME: we can likely remove this once we've graduated all Cohorts with documents in period exercises.
                // The last Cohort cycles with documents in period exercises is the 62 cycle which is slated to graduate
                // 2026-01-06.
                scope.openDocument = document => {
                    NavigationHelperMixin.loadUrl(document.url, '_blank');
                };
            },
        };
    },
]);
